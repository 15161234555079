
.indexcontainer{
	
	align-content: stretch;
	display: grid;
	background-color: lightgrey;
	grid-template-columns: 1fr ;
	min-height: 100vh;
	height: 100%
}

@media (min-width: 400px) {
	.indexcontainer { 
		
		grid-template-columns: 1fr 7fr 1fr; }
	.indexcontainer .menu {
		display: block;
	
		min-height: 100vh;
		height: 100%;
	
	
		
	}
  }
.wrapmenu{
	height: 100%;
}
.menulink {
	
	list-style-type: none;
	justify-content: start;
	
}
.header{
	font-size: large;
	font-weight: bold;
}

.container{
	overflow: hidden;
	
}
.menu{
	display: none;
	background-color: dimgray;
	padding: 1em;

	
}
.menulink >  a:any-link {

	color: #ccc;
	

	align-items: flex-start;
	text-decoration: none;
	
  }
  .menulink > a:hover{

	color: whitesmoke;
	

	align-items: flex-start;
	text-decoration: none;
	display: block;
  }



.tile {
	border:  solid red
}


.gantt-container{
  max-width: 1200px; 
	min-width: 650px;  
padding: 20px;
	margin: 0; 
overflow: hidden;
	overflow-x: scroll;
}
.gantt-container::-webkit-scrollbar {
	display: none;
  }



.item-row { 
	
	display: grid;
	grid-template-columns:30px 30px 200px 100px 100px 200px repeat( auto-fill, 10px );
	border-left: 1px solid #ccc;
	border-bottom:1px solid #ccc; 
}
.item-row > span { 

	border-right: 1px solid #ccc;
   align-items: center;
   text-align: center;

}

.topnaam{ 

	background-color: #DCDCDC;
	border-right: 1px solid #ccc;
	position: sticky;

   text-align: center;
  align-self: start;
   width: 200px;
  
   left: -20px;
   z-index: 100;

}
 .naam{ 

	background-color: #fff;
	
	
	position: sticky;

  
  
   width: 200px;
  
   left: -20px;
   z-index: 100;

}

.naam > a:link, a:visited {
	background-color: white;
	color: black;
	

	text-align: center;
	text-decoration: none;
	display: inline-block;
  }


.warningtext {
	color: red;
	font-size: 8px;

}

.warningtext > a:link {
	color: red;
	font-size: 8px;

}

.chart-row {  

	border: 1px solid #000; 
	background-color: #DCDCDC;
}
.chart-row span {  
	text-align: center;
	display: block;  border-right: 1px solid rgba(0, 0, 0, 0.3);
	
	
}
.chart-row maand {  
	text-align: center;
	display: block;  border-right: 1px solid rgba(0, 0, 0, 0.3);
	white-space: nowrap;
	overflow: hidden;

}

.col-md-4 [class*='col'] {
	text-align: match-parent;
	background-color: whitesmoke;
	background-clip: content-box;
	min-height: 280px;
	margin: 15px;
	
  }

  .dropdown-toggle.nav-link strong, strong:hover, ::after {
	  color: black;
	 
  }

 
 

