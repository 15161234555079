
  .actie {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
font-size: x-small;
  border: 2px solid goldenrod;
  border-radius: 5px;
  
  z-index: 99;
  padding: 4px;
  margin: 1px;
  }
  
.actie-handle {
  position: absolute;
 
  border-radius: 2px;
  bottom: 0px;
  left: 5%;
 width:90% ;
  border-bottom: 3px solid goldenrod;
  cursor: ns-resize; 


  
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
      transition-property: height, visibility;
      transition-duration: .35s;
  }
}
.hide {
  display: none
}
.dag {
  display: block;
  border: 1px solid black;
  position: sticky;
  top: 0;
  z-index: 100;
 background-color: white;
  padding: 3px;

  opacity: 0.5;
  
  
}
.uur {
 
  opacity:0.5;
}
.uurnummer {
  display: flex;
  background-color: white;
  position: sticky;
  left: 0;
  z-index: 101;
  justify-content: center;
  align-items: flex-start;
  
  
}


  .agenda-container {
   
    height: 400px;
   
    overflow: hidden;
    
   overflow: scroll;
    border: 1px solid lightgrey;
    
    display: grid;
  
    
    grid-template-rows: [dagstart] 1fr repeat(145, 7px);
    
    
    background-color: white;
    
  }
  .agenda-container::-webkit-scrollbar {
    display: none;
    }
